import React from 'react'
import {Link} from 'gatsby'
import {ErrorWrap} from './404.style'
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../layout/layout'

const ErrorArea = () => {
    const url = 'https://jardipiscines.netlify.app/';

    const bannerQueryData = useStaticQuery(graphql `
        query ErrorBannerQuery {
            file(relativePath: {eq: "images/piscine-hs.jpeg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }      
    `);

    const imageData = bannerQueryData.file.childImageSharp.fluid
    return (
        <Layout url={url}>
            <ErrorWrap fluid={imageData}>
                <h1>404</h1>
                <h2>la piscine est hors d'usage ici ^-^</h2>
                <Link to="/" className="error-btn">Retour à l'accueil</Link>
            </ErrorWrap>
        </Layout>
    )
}


export default ErrorArea;