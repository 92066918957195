import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import {device} from '../../theme'

export const ErrorWrap = styled(BackgroundImage) `
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    min-width: 100%;
    margin: 0 auto;
    padding: calc(100vh / 3) 0;
    /* height: 100%; */
    min-height: 100vh;
    text-align: center;
    z-index: 2;
    h1{
        color: white;
        font-size: 200px;
        letter-spacing: 5px;
        margin: 0;
        text-shadow: rgba(0,0,0,0.8) 0.1em 0.1em 0.2em;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        line-height: 1;
        @media ${device.large}{
            font-size: 150px;
        }
        @media ${device.medium}{
            font-size: 100px;
        }
        @media ${device.small}{
            font-size: 80px;
        }
    }

    &.gatsby-image-wrapper{
        z-index: 2!important;
    }
    
    h2{
        font-size: 50px;
        margin-bottom: 30px;
        color: white;
        text-shadow: rgba(0,0,0,0.8) 0.1em 0.1em 0.2em;
        margin-top: 10px;
        font-family: 'Poppins', sans-serif;
        line-height: 1;
        @media ${device.medium}{
            font-size: 40px;
        }
    }
    .error-btn {
        position: relative;
        font-family: 'Poppins', sans-serif;
        display: inline-block;
        background: #001c43;
        padding: 13px 25px;
        color: #fff!important;
        text-decoration: none;
        border-radius: 5px;
        border: 1px solid #001c43!important;
        transition: all .3s;
        z-index: 3;
        &:hover{
            background: transparent!important;
            color: #001c43!important;
        }
    }
`;

export const ErrorDetailsHeader = styled(BackgroundImage) `
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    /* padding: 130px 0; */
    display: flex;
    align-items: center;
    & > .row{
        flex-wrap: nowrap;
        @media ${device.small}{
            flex-wrap: wrap;
        }
    }
    @media ${device.xlarge}{
        .col-1.offset-1 {
            margin-left: 0;
        }
    }
    @media ${device.medium}{
        .col-1.offset-1 {
            margin-left: 20vw;
        }
    }
    @media ${device.small}{
        min-height: 450px;
        padding-top: 150px;
        .col-1.offset-1 {
            margin-left: 10vw;
        }
    }
    &.gatsby-image-wrapper{
        z-index: 2!important;
    }
`;